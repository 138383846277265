//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import JcRange from '@/components/JcRange.vue'
import Verify from '@/components/verifition/Verify'
import { _register, _getRegisterSmsPin } from '@/api/user'
import Regisxqf from './regisxqf.vue'
import Servicestered from './servicestered.vue'
export default {
  components: {
    JcRange,
    Regisxqf,
    Servicestered,
    Verify,
  },
  data() {
    return {
      name: '需求方注册',
      checked: false,
      sendMsgDisabled: false,
      btnDisab: false,
      second: 60,
      swith: false,
      ruleForm: {
        userPhone: '',
        userName: '',
        smsPin: '',
        status: false, //控制是否滑动的滑块
        captchaVerification: '', //滑块验证码的回值
      },
      rules: {},
    }
  },
  created() {
    this.ruleForm.type = this.$route.query.type
    if (this.$route.query.type == 1) {
      this.name = '需求方注册'
    } else if (this.$route.query.type == 2) {
      this.name = '服务方注册'
    }
  },
  methods: {
    //滑块验证码成功
    success(res) {
      // console.log(滑块验证码成功);
      this.ruleForm.captchaVerification = res.captchaVerification
      this.getmobilereg()
    },
    useVerify() {
      if (!this.ruleForm.userPhone || !this.ruleForm.userName) {
        this.$message('请输入联系人及手机号')
      } else {
        this.$refs.verify.show()
      }
    },
    // 点击发送验证码的事件
    getmobilereg() {
      if (
        /^1[123456789]\d{9}$/.test(this.ruleForm.userPhone) &&
        this.ruleForm.userName != ''
      ) {
        // if (!this.ruleForm.status) {
        //   this.$message('请验证滑块')
        // } else {
        this.second = 60
        this.timers()
        _getRegisterSmsPin({
          phone: this.ruleForm.userPhone,
          captchaVerification: this.ruleForm.captchaVerification,
        }).then((res) => {
          if (res.code == '1') {
            this.yzmCode = true
          } else {
            this.yzmCode = false
          }
        })
        // }
      } else {
        this.$message('请完善注册信息')
      }
    },
    timers() {
      this.sendMsgDisabled = true
      let auth_timer = setInterval(() => {
        //定时器设置每秒递减
        this.second-- //递减时间
        if (this.second <= 0) {
          this.sendMsgDisabled = false //60s时间结束还原v-show状态并清除定时器
          clearInterval(auth_timer)
          if (auth_timer) {
            this.yzmCode = false
            this.ruleForm.smsPin = ''
            this.$refs.jcRange.aaa()
            this.onMpanelError()
          }
        }
      }, 1000)
    },
    register() {
      if (this.checked) {
        if (this.yzmCode) {
          let from = {
            userPhone: this.ruleForm.userPhone,
            userName: this.ruleForm.userName, //手机号
            smsPin: this.ruleForm.smsPin, //验证码
            type: this.ruleForm.type,
          }
          this.btnDisab = true
          _register(from)
            .then((res) => {
              if (res.code == 1) {
                this.btnDisab = false
                this.$message('注册成功')
                this.$router.push('/homes/login')
              } else if (res.code == 2) {
                clearInterval(this.timer)
                this.btnDisab = false
              } else {
                this.btnDisab = false
              }
            })
            .catch(() => {
              this.btnDisab = false
            })
        } else {
          this.$message('请输入联系人，手机号，验证码进行注册')
          this.btnDisab = false
        }
      } else {
        this.$message('请勾选我已阅读并同意需求方用户注册协议')
      }
    },
    //滑块成功
    onMpanelSuccess() {
      // alert('滑块成功');
      this.ruleForm.status = true
      this.swith = true
    },
    //滑块失败
    onMpanelError() {
      this.swith = false
      this.ruleForm.status = false
    },
    // this.$router.push("/homelogin");
    // 注册协议
    registered() {
      this.$refs.regisxqf.handlexsyc()
    },
    servicestered() {
      this.$refs.servicestered.handlexsyc()
    },
  },
}
